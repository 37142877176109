<template>
  <div>
    <section class="landingbg" style="padding-bottom: 6rem">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3 class="slider-three__title">Let's get you Covered!</h3>
            <div class="environment_handler">
              <select class="form-select" v-model="distributor" @change="onChangeDistributor($event)">
                <option value="1SB">1SB</option>
              </select>
              <select class="form-select" style="margin-top: 10px;" v-model="environment"
                @change="onChangeEnvironment($event)">
                <option value="dev">Dev</option>
                <option value="test">Test</option>
                <option value="demo">Demo</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <h4 class="mb-3" style="text-align: left; color: white; font-size: 1rem">
            Assisted
          </h4>
          <swiper :modules="modules" :slides-per-view="4" :space-between="20" :autoplay="{ delay: 100 }"
            :pagination="{ clickable: true }" :breakpoints="{
              // when window width is >= 640px
              100: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide v-for="(item, index) in features" :key="index">
              <div class="mb-5">
                <div class="item">
                  <div class="features-two__card">
                    <div class="features-two__card__bg">
                      <div class="features-two__card__bg__inner" style="
                        background-image: url(https://bracketweb.com/insuba-html/assets/images/resources/features-2-2.jpg);
                      "></div>
                    </div>
                    <a :href="item.link" target="_blank">
                      <div class="features-two__card__content">
                        <div class="features-two__card__icon-box">
                          <span class="features-two__card__icon">
                            <img :src="require(`@/assets/landingPage/${item.icon}`)" alt="" />
                          </span>
                        </div>
                        <h2 class="features-two__card__title">
                          <a :href="item.link" target="_blank">{{ item.title }}
                          </a>
                        </h2>
                      </div>
                      <a :href="item.link" target="_blank" class="features-two__card__btn">
                        <span class="features-two__card__btn__icon">
                          <img src="../assets/landingPage/arrow.svg" />
                        </span>
                      </a>
                    </a>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="row">
          <h4 class="mb-3" style="text-align: left; color: white; font-size: 1rem">
            DIY (Non Assisted)
          </h4>
          <swiper :modules="modules" :slides-per-view="4" :space-between="20" :autoplay="{ delay: 100 }"
            :pagination="{ clickable: true }" :breakpoints="{
              // when window width is >= 640px
              100: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide v-for="(item, index) in diyFeatures" :key="index">
              <div class="mb-5">
                <div class="item">
                  <div class="features-two__card">
                    <div class="features-two__card__bg">
                      <div class="features-two__card__bg__inner" style="
                          background-image: url(https://bracketweb.com/insuba-html/assets/images/resources/features-2-2.jpg);
                        "></div>
                    </div>
                    <a target="_blank" :href="item.link">
                      <div class="features-two__card__content">
                        <div class="features-two__card__icon-box">
                          <span class="features-two__card__icon">
                            <img :src="require(`@/assets/landingPage/${item.icon}`)
                              " alt="" />
                          </span>
                        </div>
                        <h2 class="features-two__card__title">
                          <a target="_blank" :href="item.link">{{ item.title }}
                          </a>
                        </h2>
                      </div>
                      <a target="_blank" :href="item.link" class="features-two__card__btn">
                        <span class="features-two__card__btn__icon">
                          <img src="../assets/landingPage/arrow.svg" />
                        </span>
                      </a>
                    </a>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
SwiperCore.use([Autoplay]);
import { uiUrls } from "../utils/constants";
// Import Swiper styles
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      features: [],
      diyFeatures: [],
      distributor: "1SB",
      environment: "demo",
    };
  },
  mounted() {
    this.features = uiUrls["1SB"].demo.features;
    this.diyFeatures = uiUrls["1SB"].demo.diyFeatures;
  },
  methods: {
    onChangeDistributor($event) {
      this.distributor = $event.target.value;
    },
    onChangeEnvironment($event) {
      this.environment = $event.target.value;
      this.features = uiUrls[this.distributor][this.environment]["features"];
      this.diyFeatures = uiUrls[this.distributor][this.environment]["diyFeatures"];
    },
  },
};
</script>
<style scoped>
body {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
}

.landingbg {
  background-image: url(../assets/slider-3-bg-1.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 100% 100%;
  padding: 0;
  position: relative;
  min-height: 50pc;
  z-index: 9;
}

h3 {
  font-size: 36px;
  color: white;
  text-align: center;
  line-height: normal;
  padding-top: 120px;
}

.item {
  height: 12pc;
}

features-one__card {
  padding: 5px;
  border-radius: 10px;
  background-color: var(--insuba-white, #ffffff);
  transition: all 500ms linear;
  z-index: 999;
  height: 100%;
}

.features-one__card:hover {
  transform: translateY(-30px);
}

.features-one__card__content {
  position: relative;
  padding: 6px 27px 17px;
  background-color: var(--insuba-black4, #0f3564);
  border-radius: inherit;
  overflow: hidden;
}

.features-one__card__content::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: -100%;
  bottom: -100%;
  border-radius: inherit;
  background-color: var(--insuba-base, #0365d3);
  transition: all 500ms ease;
}

.features-one__card__content::after {
  content: "";
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: var(--insuba-blue, #7bb9ff);
  position: absolute;
  right: -12px;
  bottom: -10px;
  opacity: 0;
  z-index: 1;
  transition: all 500ms ease 400ms;
}

.features-one__card:hover .features-one__card__content::before {
  right: 0;
  bottom: 0;
}

.features-one__card:hover .features-one__card__content::after {
  opacity: 1;
}

.features-one__card__icon {
  position: relative;
  margin-bottom: 13px;
  display: inline-flex;
  font-size: 51px;
  color: var(--insuba-white, #ffffff);
  z-index: 2;
}

.features-one__card__icon svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.features-one__card__title {
  position: relative;
  margin-bottom: 4px;
  font-size: 22px;
  font-weight: 700;
  color: var(--insuba-white, #ffffff);
  line-height: 1.318;
  z-index: 2;
}

.features-one__card__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
  text-decoration: none;
}

.features-one__card__title a:hover {
  background-size: 100% 1px;
}

.features-one__card__text {
  position: relative;
  margin: 0;
  color: var(--insuba-white, #ffffff);
  z-index: 2;
}

.features-one__card__btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--insuba-white, #ffffff);
  border-radius: 50%;
  transition: all 500ms ease;
  z-index: 2;
}

.features-one__card__btn__icon {
  display: inline-flex;
  font-size: 14px;
  color: var(--insuba-black4, #0f3564);
  transition: all 500ms ease;
}

.features-one__card__btn__icon svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.features-one__card__btn:hover .features-one__card__btn__icon {
  transform: rotate(90deg);
}

.features-one--service-details {
  padding: 0;
  margin: 0;
}

.features-one--service-details .features-one__column {
  width: 50%;
}

@media (max-width: 575px) {
  .features-one--service-details .features-one__column {
    width: 100%;
  }
}

.features-one--service-details .features-one__card:hover {
  transform: translateY(0px);
}

.features-two {
  background: linear-gradient(180deg,
      transparent,
      var(--insuba-white5, #f0f5f5));
}

.features-two__carousel.owl-carousel .owl-nav.disabled+.owl-dots {
  margin-top: 74px;
}

.features-two__carousel.owl-carousel .owl-dots,
.features-two__carousel.owl-carousel .owl-nav {
  margin-top: 74px;
}

.features-two__carousel.owl-carousel .owl-nav button {
  background-color: var(--insuba-white, #ffffff);
}

.features-two__card {
  position: relative;

  background-color: var(--insuba-white, #ffffff);
  border-radius: 30px;
  height: 100%;
}

@media (max-width: 360px) {
  .features-two__card {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.features-two__card__bg {
  width: 100%;
  height: 100%;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: inherit;
  overflow: hidden;
}

.features-two__card__bg::before {
  content: "";
  width: 200px;
  height: 100px;
  position: absolute;
  top: -58px;
  left: -93px;
  transform: rotate(-45deg);
  background-color: var(--insuba-base, #0365d3);
}

.features-two__card__bg__inner {
  width: 100%;
  height: 100%;
  position: relative;
  position: absolute;
  right: -100%;
  bottom: -100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: inherit;
  z-index: 1;
  transition: all 500ms ease;
}

.features-two__card__bg__inner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: RGBA(var(--insuba-base-rgb, 3, 101, 211), 0.89);
  border-radius: inherit;
}

.features-two__card:hover .features-two__card__bg__inner {
  right: 0px;
  bottom: 0px;
}

.features-two__card__content {
  position: relative;
  text-align: center;
  z-index: 2;
  padding: 6px 27px 17px;
}

.features-two__card__icon-box {
  margin: 0 auto 15px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--insuba-white5, #f0f5f5);
  background: linear-gradient(180deg,
      transparent 50%,
      RGBA(var(--insuba-base-rgb, 3, 101, 211), 0.09)) 50%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.features-two__card:hover .features-two__card__icon-box {
  background: linear-gradient(180deg,
      transparent 50%,
      RGBA(var(--insuba-white-rgb, 255, 255, 255), 0.09)) 50%;
}

.features-two__card__icon {
  display: inline-flex;
  font-size: 50px;
  color: var(--insuba-black4, #0f3564);
  transition: all 500ms ease;
}

.features-two__card__icon img {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.features-two__card__icon svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.features-two__card:hover .features-two__card__icon {
  color: var(--insuba-white, #ffffff);
  -webkit-animation-name: wobble-horizontal-hover;
  animation-name: wobble-horizontal-hover;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.features-two__card__title {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.318;
  text-transform: capitalize;
}

.features-two__card__title a {
  color: black;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 1px no-repeat;
  transition: all 500ms ease;
  text-decoration: none;
}

.features-two__card__title a:hover {
  background-size: 100% 1px;
  color: white;
}

.features-two__card__text {
  margin: 0;
  transition: all 500ms ease;
}

.features-two__card:hover .features-two__card__title,
.features-two__card:hover .features-two__card__text,
.features-two__card:hover .features-two__card__title a {
  color: var(--insuba-white, #ffffff);
}

.features-two__card__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
  position: absolute;
  left: 50%;
  bottom: -23px;
  transform: translateX(-50%);
  background-color: var(--insuba-base, #0365d3);
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
  transition: all 500ms ease;
}

.features-two__card__btn__icon {
  display: inline-flex;
  font-size: 20px;
  color: var(--insuba-white, #ffffff);
  transition: all 500ms ease;
}

.features-two__card__btn__icon img {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.features-two__card__btn__icon svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.features-two__card__btn:hover .features-two__card__btn__icon {
  transform: rotate(90deg);
}

.features-two__card:hover .features-two__card__btn {
  background-color: var(--insuba-black4, #0f3564);
}

.environment_handler {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  float: right;
}

::v-deep(.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet),
::v-deep(.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet) {
  width: 13px !important;
  height: 13px !important;
  background: #000;
  border: 1px solid #007aff;
}
</style>
