export const uiUrls = {
    "1SB": {
        dev: {
            features: [
                {
                    title: "Life Term",
                    icon: "term.svg",
                    link: "https://dev.term.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Health",
                    icon: "health.svg",
                    link: "https://dev.health.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Investment",
                    icon: "savings.svg",
                    link: "https://dev.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Annuity",
                    icon: "annuity.svg",
                    link: "https://dev.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000&journeyType=annuity",
                },
                {
                    title: "Pension",
                    icon: "pension.svg",
                    link: "https://dev.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000&journeyType=pension",
                },
                {
                    title: "Motor Insurance",
                    icon: "motor.svg",
                    link: "https://dev.motor.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
            ],
            diyFeatures: [
                {
                    title: "Life Term",
                    icon: "term.svg",
                    link: "https://dev.term.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Health",
                    icon: "health.svg",
                    link: "https://dev.health.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Investment",
                    icon: "savings.svg",
                    link: "https://dev.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Annuity",
                    icon: "annuity.svg",
                    link: "https://dev.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010&journeyType=annuity",
                },
                {
                    title: "Pension",
                    icon: "pension.svg",
                    link: "https://dev.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010&journeyType=pension",
                },
                {
                    title: "Motor Insurance",
                    icon: "motor.svg",
                    link: "https://dev.motor.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
            ],
        },
        test: {
            features: [
                {
                    title: "Life Term",
                    icon: "term.svg",
                    link: "https://test.term.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Health",
                    icon: "health.svg",
                    link: "https://test.health.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Investment",
                    icon: "savings.svg",
                    link: "https://test.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Annuity",
                    icon: "annuity.svg",
                    link: "https://test.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000&journeyType=annuity",
                },
                {
                    title: "Pension",
                    icon: "pension.svg",
                    link: "https://test.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000&journeyType=pension",
                },
                {
                    title: "Motor Insurance",
                    icon: "motor.svg",
                    link: "https://test.motor.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
            ],
            diyFeatures: [
                {
                    title: "Life Term",
                    icon: "term.svg",
                    link: "https://test.term.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Health",
                    icon: "health.svg",
                    link: "https://test.health.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Investment",
                    icon: "savings.svg",
                    link: "https://test.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Annuity",
                    icon: "annuity.svg",
                    link: "https://test.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010&journeyType=annuity",
                },
                {
                    title: "Pension",
                    icon: "pension.svg",
                    link: "https://test.saving.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010&journeyType=pension",
                },
                {
                    title: "Motor Insurance",
                    icon: "motor.svg",
                    link: "https://test.motor.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
            ],
        },
        demo: {
            features: [
                {
                    title: "Life Term",
                    icon: "term.svg",
                    link: "https://demo.term.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Health",
                    icon: "health.svg",
                    link: "https://demo.health.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Investment",
                    icon: "savings.svg",
                    link: "https://demo.savings.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "Annuity",
                    icon: "annuity.svg",
                    link: "https://demo.savings.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000&journeyType=annuity",
                },
                {
                    title: "Pension",
                    icon: "pension.svg",
                    link: "https://demo.savings.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000&journeyType=pension",
                },
                {
                    title: "Motor Insurance",
                    icon: "motor.svg",
                    link: "https://demo.motor.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000000",
                },
                {
                    title: "NBFC",
                    icon: "nbfc.svg",
                    link: "https://loan.dev.insurance.1silverbullet.com/#/",
                },
            ],
            diyFeatures: [
                {
                    title: "Life Term",
                    icon: "term.svg",
                    link: "https://demo.term.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Health",
                    icon: "health.svg",
                    link: "https://demo.health.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Investment",
                    icon: "savings.svg",
                    link: "https://demo.savings.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
                {
                    title: "Annuity",
                    icon: "annuity.svg",
                    link: "https://demo.savings.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010&journeyType=annuity",
                },
                {
                    title: "Pension",
                    icon: "pension.svg",
                    link: "https://demo.savings.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010&journeyType=pension",
                },
                {
                    title: "Motor Insurance",
                    icon: "motor.svg",
                    link: "https://demo.motor.insurance.1silverbullet.tech/#/?id=00000000-0000-0000-0000-000000000010",
                },
            ],
        },
    },
};
